import React, { useState } from "react";
import { Box } from "@mui/material";
import { Collapse } from "react-collapse";

export default function StepperHeading({
  mainHeading,
  subHeading,
  stepNumber,
  activeSubItemStatus,
  activeStepStatus,
}) {
  const [first, setfirst] = useState(false);
  return (
    <>
      <Box
        onClick={() => setfirst(!first)}
        sx={{
          height: { xs: "30px", sm: "32px", md: "40px", lg: "44px" },
          // height: "70px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "start",
          textTransform: "capitalize",
          fontSize: {
            xs: "12px",
            sm: "14px",
            md: "14px",
            lg: "16px",
            xl: "17px",
          },
          mb: stepNumber === 5 ? "-14px" : "",
        }}
      >
        <div
          style={{
            color: "white",
            fontWeight: "600",
          }}
        >
          {mainHeading}
        </div>
      </Box>
      <Collapse isOpened={activeStepStatus === "activeStep" ? true : false}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            height: stepNumber === 5 || stepNumber === 3 ? "160px" : "120px",
            gap: { md: "10px", sm: "10px", lg: "10px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              // height: "10%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              fontSize: {
                xs: "11px",
                sm: "12px",
                md: "13px",
                lg: "16px",
                xl: "18px",
              },
              height: "16px",
            }}
          >
            <div
              style={{
                color:
                  activeSubItemStatus === "activeFirstSubItem"
                    ? "white"
                    : "#797979",
                fontWeight: "600",
                height: "16px",
              }}
            >
              {subHeading[0]}
            </div>
          </Box>

          <Box
            sx={{
              width: "100%",
              // height: "10%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              fontSize: {
                xs: "11px",
                sm: "12px",
                md: "13px",
                lg: "16px",
                xl: "18px",
              },
              height: "16px",
            }}
          >
            <div
              style={{
                color:
                  activeSubItemStatus === "activeSecondSubItem"
                    ? "white"
                    : "#797979",
                fontWeight: "600",
                height: "16px",
              }}
            >
              {subHeading[1]}
            </div>
          </Box>
          {(stepNumber === 3 || stepNumber === 5) && (
            <Box
              sx={{
                width: "100%",
                // height: "10%",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                textAlign: "start",
                fontSize: {
                  xs: "11px",
                  sm: "12px",
                  md: "13px",
                  lg: "16px",
                  xl: "18px",
                },
                height: "16px",
              }}
            >
              <div
                style={{
                  color:
                    activeSubItemStatus === "activeThirdSubItem"
                      ? "white"
                      : "#797979",
                  fontWeight: "600",
                  height: "16px",
                }}
              >
                {subHeading[2]}
              </div>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
}
