import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import bannerImage from "../../Assets/images/banner.jpg";

const Banner = () => {
  return (
    <Box
      sx={{
        "& *": {
          fontFamily: "Outfit",
        },
      }}
    >
      <Wrapper>
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
          my={"10%"}
          sx={{
            color: "#fff",
            px: { xs: "20px", lg: "0px" },
            gap: { xs: 3, md: 1 },
            "& *": {
              fontFamily: "Outfit",
            },
          }}
        >
          <Grid item xs={12} md={5} gap={2}>
            <Typography sx={{ fontSize: "34px", fontWeight: 700, mb: "1%" }}>
              Concluding with Excellence and Vision
            </Typography>
            <Typography
              sx={{ color: "#CCCCCC", fontSize: "15px", fontFamily: "Outfit" }}
            >
              Welcome to our Client Offboarding Page. As we reach the conclusion
              of our collaborative journey, we want to thank you for being an
              integral part of our esteemed community of partners and clients.
              Our streamlined offboarding process ensures a smooth transition,
              providing the support and information needed as we finalize your
              project. During this phase, we will summarize our achievements,
              address any final details, and ensure you are prepared for
              continued success. Let’s celebrate your accomplishments and
              anticipate new opportunities ahead!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <img
                src={bannerImage}
                alt="bannerImage"
                width={"100%"}
                // height={"90%"}
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  // height: "200px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default Banner;
