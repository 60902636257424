import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, Divider } from "@mui/material";
import image from "../../../Assets/images/home.png";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../Utils";
import { TrackCurrentPage } from "..";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import MainBox from "../../../components/common/MainBox";
import {
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
} from "firebase/database";
import ModalForExisitingUser, {
  openModal,
  openModalOnce,
} from "../../../components/ModalForExistUser/ModalForExistUser";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const existingUser = atom(null);

export const userDataAtom = atom({
  homeA: "",
  homeB: "",
  homeC: dayjs("17-07-2023").format("DD/MM/YYYY"),
  homeD: "",
  oneA: [],
  oneB: "",
  twoA: "",
  twoB: "",
  threeA: "",
  threeB: "",
  threeC: "",
  fourA: "",
  fourB: "",
  fiveA: "",
  fiveB: "",
  fiveC: "",
  reviewA: false,
  reviewB: false,
  reviewC: false,
  videoUrl: "",
});

function Homes(props) {
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);
  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [fieldRequiredError, setfieldRequiredError] = useState(false);
  const [inputRequiredError, setInputRequiredError] = useState({
    answer1: false,
    answer2: false,
  });
  const [emaiIsValid, setEmailIsValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleEmailChange = (value) => {
    const res = validateEmail(value.target.value);
    setuserInfo({
      ...userInfo,
      homeA: value.target.value,
    });
    if (res) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  //Disable Button
  useEffect(() => {
    if (userInfo?.homeA === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    if (validateEmail(userInfo?.homeA)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.homeC === "Invalid Date" || userInfo?.homeD === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [userInfo]);
  // const checkEmailExist = async () => {
  //   setLoading(true);
  //   if (userInfo?.homeA !== "" && emaiIsValid) {
  //     const db = getDatabase();
  //     const checkingIsUserExist = await get(
  //       query(
  //         ref(db, "OnBoardingUsers"),
  //         orderByChild("homeA"),
  //         equalTo(userInfo?.homeA)
  //       )
  //     );
  //     console.log("checkingIsUserExist", checkingIsUserExist.val());
  //     const resp = checkingIsUserExist.val();
  //     if (resp) {
  //       const extractedValues = Object.entries(
  //         checkingIsUserExist.val()
  //       ).reduce((acc, [key, value]) => {
  //         return { ...acc, ...value };
  //       }, {});
  //       setExistingUserInfo(extractedValues);
  //       if (extractedValues?.homeA === userInfo?.homeA) {
  //         console.log("openOnce", openOnce);
  //         if (!openOnce) {
  //           setOpen(true);
  //         } else {
  //           setcurrentPage((page) => page + 1);
  //         }
  //         setOpenOnce(true);
  //         setLoading(false);
  //       }
  //     } else {
  //       setcurrentPage((page) => page + 1);
  //     }
  //     // checkingIsUserExist?.forEach((item) => {
  //     //   setExistingUserInfo(item?.val());
  //     //   console.log("items value", item?.val());
  //     // });
  //     console.log("existingUserInfo", existingUserInfo);
  //     console.log("userInfoInConsole", userInfo);
  //     // console.log("extractedValues", extractedValues);
  //     // if (extractedValues !== null) {
  //     //   if (extractedValues?.homeA === userInfo?.homeA) {
  //     //     setOpen(true);
  //     //     setLoading(false);
  //     //     console.log("compare email", extractedValues?.homeA, userInfo?.homeA);
  //     //   }
  //     // } else {
  //     //   setcurrentPage((page) => page + 1);
  //     // }

  //     // if (existingUserInfo?.homeA === userInfo?.homeA) {
  //     //   setOpen(true);
  //     //   setLoading(false);
  //     //   console.log("compare email", existingUserInfo?.homeA, userInfo?.homeA);
  //     // } else {
  //     //   setcurrentPage((page) => page + 1);
  //     // }
  //     setLoading(false);
  //     // setExistingUserInfo(refdata.forEach((item) => console.log(item?.val())));
  //   } else {
  //     setLoading(false);
  //     setIsEmailDirty(true);
  //     setfieldRequiredError(true);
  //   }
  // };

  return (
    <MainBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: "2%",
        }}
      >
        <FormHeading text={"Experience Survey"} />
      </Box>
      <Divider sx={{ bgcolor: "#fff", mb: "4%" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#252525",
          borderRadius: "10px ",
          width: "90%",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            height: {
              xs: "120px",
              sm: "125px",
              md: "130px",
              lg: "150px",
              xl: "170px",
            },
          }}
        >
          <img
            style={{
              objectFit: "fill",
              objectPosition: "center",
              borderRadius: "10px 10px 0px 0",
            }}
            src={image}
            alt={"imageSwap"}
            height={"100%"}
            width={"100%"}
          />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "5%",
            columnGap: "30px",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              margin: "1%",
              // flexGrow: 2,
            }}
          >
            <Typography
              fontWeight={200}
              textAlign={"start"}
              sx={{
                color: "#00BDDF",
                fontWeight: "600",
                fontSize: "21px",
              }}
            >
              Business Email
            </Typography>
            <Box
              sx={{
                bgcolor: "#3C3C3C",
                borderRadius: "10px",
                padding: "30px",
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "white",
                  fontWeight: "400",
                  marginBottom: "1%",
                }}
              >
                Please enter the same business email used for the onboarding
                form
              </Typography>
              <TextField
                id="filled-basic"
                multiline={true}
                maxRows={3}
                placeholder="Lorem@example.com"
                variant="filled"
                type="email"
                sx={{
                  width: "100%",
                  textarea: {
                    "::placeholder": { color: "#FFFFFF" },
                    color: "white",
                  },
                }}
                value={userInfo?.homeA}
                onChange={(value) => handleEmailChange(value)}
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "transparent",
                    marginLeft: "0rem",
                    marginRight: "0rem",
                    marginTop: "0rem",
                  },
                }}
                inputProps={{
                  onBlur: () => {
                    setIsEmailDirty(true);
                  },
                }}
                helperText={
                  !emaiIsValid && isEmailDirty
                    ? userInfo?.homeA == ""
                      ? "This field is required"
                      : "Please enter valid email"
                    : ""
                }
                error={!emaiIsValid && isEmailDirty ? true : false}
              />
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: { xs: "wrap", md: "nowrap" },
            padding: "5%",
            columnGap: "2rem",
          }}
        >
          <Box
            sx={{
              // width: "90%",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              margin: "1%",
              flexGrow: 1,
              width: { xs: "200px", md: "300px" },
            }}
          >
            <Typography
              fontWeight={200}
              textAlign={"start"}
              sx={{
                color: "#00BDDF",
                fontWeight: "600",
                fontSize: {
                  xs: "17px",
                  sm: "17px",
                  md: "18px",
                  lg: "22px",
                  xl: "25px",
                },
                marginLeft: "1.2%",
              }}
            >
              Your Name
            </Typography>
            <Box
              sx={{
                bgcolor: "#3C3C3C",
                borderRadius: "10px",
                padding: { xs: "20px", md: "30px" },
                // padding: "1.8rem",
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "white",
                  fontWeight: "400",
                  marginBottom: "1%",
                  marginLeft: "1%",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                }}
              >
                {/* Enter your business email we will if already have your form
                  filled */}
                Enter your name
              </Typography>
              <TextField
                id="filled-basic"
                multiline={true}
                maxRows={3}
                placeholder="John Doe"
                variant="filled"
                // autoComplete="off"
                sx={{
                  width: "100%",
                  textarea: {
                    "::placeholder": { color: "#FFFFFF" },
                    color: "white",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "17px",
                    },
                  },
                }}
                value={userInfo?.homeD}
                onChange={(value) =>
                  setuserInfo({
                    ...userInfo,
                    homeD: value.target.value,
                  })
                }
                FormHelperTextProps={{
                  style: {
                    backgroundColor: "transparent",
                    marginLeft: "0rem",
                    marginRight: "0rem",
                    marginTop: "0rem",
                  },
                }}
                inputProps={{
                  onBlur: () => {
                    setInputRequiredError((prevState) => ({
                      ...prevState,
                      answer1: true,
                    }));
                  },
                }}
                helperText={
                  inputRequiredError.answer1 && userInfo?.homeD == ""
                    ? "This field is required"
                    : ""
                }
                error={
                  inputRequiredError.answer1 && userInfo?.homeD == ""
                    ? true
                    : false
                }
              />
            </Box>
          </Box>

          <Box
            sx={{
              // width: "90%",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              margin: "1%",
              flexGrow: 1,
              width: { xs: "200px", md: "300px" },
            }}
          >
            <Typography
              fontWeight={200}
              textAlign={"start"}
              sx={{
                color: "#00BDDF",
                fontWeight: "600",
                fontSize: {
                  xs: "17px",
                  sm: "17px",
                  md: "18px",
                  lg: "22px",
                  xl: "25px",
                },
                marginLeft: "1.2%",
              }}
            >
              Date of Birth
            </Typography>
            <Box
              sx={{
                bgcolor: "#3C3C3C",
                borderRadius: "10px",
                padding: { xs: "20px", md: "30px" },
                color: "#fff",
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "white",
                  fontWeight: "400",
                  marginBottom: "1%",
                  marginLeft: "1%",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                }}
              >
                Add DOB
              </Typography>
              <DatePicker
                // defaultValue={"DD/MM/YYYY"}
                // defaultValue={dayjs(userInfo?.homeC).format("DD/MM/YYYY")}
                // value={dayjs(userInfo?.homeC).format("DD/MM/YYYY")}
                value={userInfo?.homeC}
                onChange={(e) =>
                  setuserInfo({
                    ...userInfo,
                    homeC: dayjs(e),
                  })
                }
                disableTextInput
                maxDate={dayjs()}
                disableFuture={true}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    color:
                      userInfo?.homeC === "Invalid Date" ? "#FDFDFD80" : "#fff",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "17px",
                    },
                    backgroundColor: "#444",
                    ":hover": {
                      border: "none",
                      outline: "none",
                    },
                    outline: "none",
                    border: "none",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderBottom: "1px solid #222",
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#ffff",
                  },
                  "& .MuiPickersDay-daySelected": {
                    backgroundColor: "#ff0000",
                    color: "#ffffff",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Button
          onClick={() => {
            if (!userInfo?.homeD) {
              setfieldRequiredError(true);
            } else if (userInfo?.homeD) {
              console.log("first time", currentPage);
              setcurrentPage((step) => step + 1);
            }
          }}
          variant="contained"
          disabled={isDisabled}
          sx={{
            bgcolor: "#079AB5",
            width: "fit-content",
            alignSelf: "flex-end",
            mr: "5%",
            mb: "5%",
            bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
            "&.Mui-disabled": {
              background: "#5A5A5A",
              color: "#ffff",
            },
            "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
          }}
        >
          Continue
        </Button>
      </Box>
    </MainBox>
  );
}

export default Homes;
