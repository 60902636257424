import { Box, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import { TrackCurrentQuestionNum } from "../../pages/onBoarding/FormPage/FormPage";
const InstructionBox = ({ img, stepNum }) => {
  const [instText, setInsText] = useState([]);
  const [currActiveQuesAtom, setcurrActiveQuesAtom] = useAtom(
    TrackCurrentQuestionNum
  );
  console.log("currActiveQuesAtom", currActiveQuesAtom);
  useEffect(() => {
    if (currActiveQuesAtom === 1.1) {
      setInsText([
        " Please rate our services on a scale of 1 to 10, with 1 being the lowest level of satisfaction and 10 being the highest level of satisfaction.",
      ]);
    } else if (currActiveQuesAtom === 1.2) {
      setInsText([
        " Please describe your experience working with us in your own words.",
      ]);
    } else if (currActiveQuesAtom === 2.1) {
      setInsText([
        " Please define our effectiveness in listening to your needs and concerns.",
      ]);
    } else if (currActiveQuesAtom === 2.2) {
      setInsText([
        " Please provide your assessment of our professionalism and reliability based on your experience.",
      ]);
    } else if (currActiveQuesAtom === 3.1) {
      setInsText([
        " Please describe what you appreciated or found favorable about our service.",
      ]);
    } else if (currActiveQuesAtom === 3.2) {
      setInsText([
        " Kindly provide your feedback on any areas of our service that you believe could be enhanced.",
      ]);
    } else if (currActiveQuesAtom === 3.3) {
      setInsText([
        " Kindly provide any recommendations or ideas on how we can elevate and optimize the quality of our services.",
      ]);
    } else if (currActiveQuesAtom === 4.1) {
      setInsText([
        " Kindly share the most significant milestone or accomplishment that you believe deserves special recognition within the project.",
      ]);
    } else if (currActiveQuesAtom === 4.2) {
      setInsText([
        " Kindly provide your feedback on our performance or any challenges you encountered during the project.",
      ]);
    } else if (currActiveQuesAtom === 5.1) {
      setInsText([
        " Please share your thoughts on whether you would recommend our services to a friend or colleague.",
      ]);
    } else if (currActiveQuesAtom === 5.2) {
      setInsText([
        " Identify the ideal individuals or organizations in your network that you believe would be an ideal fit for our services.",
      ]);
    } else if (currActiveQuesAtom === 5.3) {
      setInsText([
        " Please share if you would consider our services again in the future, and provide any relevant details or reasons for your decision.",
      ]);
    } else {
      setInsText([""]);
    }
  }, [currActiveQuesAtom]);

  return (
    <Box
      className="instruction-box"
      sx={{
        height: "100%",
        width: "100%",
        // background: "#464646",
        bgcolor: "#252525",
        // backgroundImage: `url(${img})`,
        backgroundSize: "100% 100%",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        borderTopLeftRadius: "10px",
        position: "relative",
        borderTopRightRadius: "10px",
        // filter: currActiveQuesAtom > 0 && "blur(5px)",
        // transition: currActiveQuesAtom > 0 && "all 1s ease-in-out",
        zIndex: 2,
        opacity: 0.8,
        position: "relative",
      }}
    >
      <>
        <Box
          component="img"
          src={img}
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            filter: currActiveQuesAtom > 0 && "blur(3px)",
            backdropFilter: currActiveQuesAtom > 0 && "blur(5px)",
            transition: currActiveQuesAtom > 0 && "all 1s ease-in-out",
            opacity: currActiveQuesAtom > 0 ? 0.3 : 1,
          }}
        />
        {currActiveQuesAtom > 0 && (
          <Box
            sx={{
              zIndex: 9999,
              height: "100%",
              px: { xs: "10px", md: "60px" },
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              textAlign={"start"}
              fontWeight={400}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                mx: "20px",
                mt: "45px",
                color: "#fff",
                // zIndex: 9999,
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "16px",
                  lg: "17px",
                  xl: "19px",
                },
                "& * span": {
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                },
              }}
            >
              <span
                style={{
                  color: "#fff",
                  marginRight: "2px",
                  fontWeight: 600,
                }}
              >
                Instructions:
              </span>
              <Typewriter
                key={instText.join("")}
                loop={1}
                typeSpeed={35}
                deleteSpeed={70}
                delaySpeed={1000}
                words={instText}
              />
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export default InstructionBox;
