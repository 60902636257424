import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import Camera from "./CameraWithHTMLVideoTag";
import CameraWithWebcam from "./CameraWithWebcam";

export default function CameraScreen() {
  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#252525",
          height: { sm: "80vh", xs: "80vh", md: "auto", lg: "auto" },
          width: "70%",
          "@media(max-width:900px)": { width: "100%" },
          alignSelf: "center",
          // "@media(max-width:1000px)": { width: "40%" },
          // "@media(max-width:800px)": { width: "60%" },
          // "@media(max-width:600px)": { width: "90%" },
          // "@media(max-width:450px)": { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { lg: "space-around", md: "space-around" },
            padding: "10px",
            // bgcolor: "red",
            height: { sm: "100%", xs: "100%" },
            // flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              margin: "2%",
            }}
          >
            <Typography
              fontWeight={200}
              textAlign={"start"}
              sx={{
                fontSize: "x-large",
                color: "#00BDDF",
                fontWeight: "600",
              }}
            >
              Express your thoughts
            </Typography>
            <Typography
              fontWeight={200}
              sx={{
                color: "white",
                fontWeight: "400",
                textAlign: "start",
                marginBottom: "1%",
                fontSize: "medium",
              }}
            >
              Record a 60 seconds video to share your honest feedback
            </Typography>
          </Box>
          {/* <Camera/> */}
          <CameraWithWebcam />
        </Box>
      </Box>
    </>
  );
}
