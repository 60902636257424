import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Radio, Typography, styled } from "@mui/material";
import { useAtom } from "jotai";
import { userDataAtom } from "../../pages/onBoarding/Home/Homes";
// import { userDataAtom } from "../../Pages/onBoarding/Home/Home.js";

const rows = [
  { key: 1, name: "Quality Satisfaction" },
  { key: 2, name: "Communication and Responsiveness" },
  { key: 3, name: "Timeliness and Deadlines" },
  { key: 4, name: "Problem-solving and Adaptability" },
  { key: 5, name: "Overall Satisfaction" },
];
const StyledTableContainer = styled(TableContainer)({
  "&::-webkit-scrollbar": {
    width: "3px",
    background: "#F0F0F0",
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#F0F0F0",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
});
const FirstTierInput = ({
  answer1,
  setcurrentQuestion,
  inputRequiredError,
}) => {
  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [error, setError] = React.useState(false);

  console.log("ShowError in tier", inputRequiredError?.answer1);
  const handleRadioChange = (rowKey, value, inputRequiredError) => {
    setcurrentQuestion(1.1);
    const updatedOptions = [...userInfo?.oneA];
    const index = updatedOptions.findIndex((option) => option[rowKey]);

    if (index !== -1) {
      updatedOptions[index] = { [rowKey]: value };
    } else {
      updatedOptions.push({ [rowKey]: value });
    }

    setSelectedOptions(updatedOptions);

    setuserInfo((prevUserData) => ({
      ...prevUserData,
      oneA: updatedOptions,
    }));
  };
  React.useEffect(() => {
    if (inputRequiredError?.answer1 && userInfo?.oneA?.length < 5) {
      console.log("Hello", inputRequiredError?.answer1);
      setError(true);
    } else {
      setError(false);
    }
  }, [inputRequiredError?.answer1, answer1, userInfo?.oneA]);
  return (
    <>
      <StyledTableContainer
        component={Paper}
        sx={{ background: "transparent", boxShadow: "none" }}
      >
        <Table
          sx={{
            minWidth: 350,
            "& .MuiTableCell-root": {
              border: "none",
              p: "4px",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                "& > .MuiTableCell-root": {
                  fontSize: "12px",
                  color: "#fff",
                },
              }}
            >
              <TableCell></TableCell>
              <TableCell> Dissatisfied</TableCell>
              <TableCell>Underwhelmed</TableCell>
              <TableCell>Ambivalent</TableCell>
              <TableCell> Moderate</TableCell>
              <TableCell>Satisfied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  ".MuiTableCell-root": {
                    fontSize: "12px",
                    color: "#fff",
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <Radio
                    value={answer1[0]}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                      },
                    }}
                    checked={userInfo?.oneA?.some(
                      (option) => option[row.key] === "dissatisfied"
                    )}
                    onChange={() => handleRadioChange(row.key, "dissatisfied")}
                  />
                </TableCell>
                <TableCell align="center">
                  <Radio
                    value={answer1[1]}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                      },
                    }}
                    checked={userInfo?.oneA?.some(
                      (option) => option[row.key] === "underwhelmed"
                    )}
                    onChange={() => handleRadioChange(row.key, "underwhelmed")}
                  />
                </TableCell>
                <TableCell align="center">
                  <Radio
                    value={answer1[2]}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                      },
                    }}
                    checked={userInfo?.oneA?.some(
                      (option) => option[row.key] === "ambivalent"
                    )}
                    onChange={() => handleRadioChange(row.key, "ambivalent")}
                  />
                </TableCell>
                <TableCell sx={{ color: "#fff" }} align="center">
                  <Radio
                    value={answer1[3]}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                      },
                    }}
                    checked={userInfo?.oneA?.some(
                      (option) => option[row.key] === "moderate"
                    )}
                    onChange={() => handleRadioChange(row.key, "moderate")}
                  />
                </TableCell>
                <TableCell align="center">
                  <Radio
                    value={answer1[4]}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                      },
                    }}
                    checked={userInfo?.oneA?.some(
                      (option) => option[row.key] === "satisfied"
                    )}
                    onChange={() => handleRadioChange(row.key, "satisfied")}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Typography
        sx={{ color: "#d32f2f", textAlign: "start", fontSize: "12px" }}
      >
        {error ? "All fields are required" : ""}
      </Typography>
    </>
  );
};

export default FirstTierInput;
