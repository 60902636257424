import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { atom, useAtom } from "jotai";
import modalImage from "../../Assets/images/modalImg.png";
import { existingUser, userDataAtom } from "../../pages/onBoarding/Home/Homes";
import { TrackCurrentPage } from "../../pages/onBoarding";

export const openModal = atom(false);
export const openModalOnce = atom(false);

export default function ModalForExisitingUser({ ExUserInfo }) {
  const [open, setOpen] = useAtom(openModal);
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);
  const [user, setUser] = useAtom(userDataAtom);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  console.log("existingUser", ExUserInfo);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "240px",
              sm: "300px",
              md: "350px",
              lg: "460px",
              xl: "500px",
            },
            bgcolor: "#252525",
            p: 4,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "#fff",
            border: "none",
          }}
        >
          <img src={modalImage} />
          <Typography
            sx={{
              mt: 2,
              fontWeight: 600,
              fontSize: { xs: "18px", md: "20px", lg: "23px" },
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Welcome {user?.homeD}!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              textAlign: "center",
              fontWeight: 300,
              fontSize: { xs: "12px", sm: "12px", lg: "14px" },
            }}
          >
            Congratulations on completing{" "}
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {user?.homeB}
            </span>
            ! It was a pleasure to work with you on{" "}
            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
              {user?.homeB}
            </span>
            . Your trust and collaboration have been invaluable throughout our
            journey
          </Typography>
          <Typography
            sx={{
              mt: 2,
              textAlign: "center",
              fontSize: { xs: "16px", md: "18px", lg: "19px" },
            }}
          >
            Thank you!
          </Typography>
          <Button
            onClick={() => setcurrentPage((page) => page + 1)}
            variant="contained"
            sx={{
              bgcolor: "#079AB5",
              width: "max-content",
              p: "10px 20px",
              borderRadius: "30px",
              fontSize: { xs: "10px", md: "10px", lg: "12px" },
              mt: 2,
              "&:hover": {
                bgcolor: "#079AB5",
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
