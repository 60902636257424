import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  SxProps,
  Divider,
} from "@mui/material";
import image from "../../../Assets/images/home.png";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../Utils";
import { TrackCurrentPage } from "../index";
import {
  DatePicker,
  DesktopDatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { existingUser, userDataAtom } from "./Homes";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import MainBox from "../../../components/common/MainBox";
import ModalForExisitingUser, {
  openModal,
  openModalOnce,
} from "../../../components/ModalForExistUser/ModalForExistUser";
import {
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
} from "firebase/database";
function BusinessInfo(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);
  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [fieldRequiredError, setfieldRequiredError] = useState(false);
  const [open, setOpen] = useAtom(openModal);
  const [openOnce, setOpenOnce] = useAtom(openModalOnce);
  const [inputRequiredError, setInputRequiredError] = useState({
    answer1: false,
    answer2: false,
  });
  const [loading, setLoading] = useState(false);
  const [existingUserInfo, setExistingUserInfo] = useAtom(existingUser);
  const [emaiIsValid, setEmailIsValid] = useState(false);
  console.log("homec", userInfo?.homeC);

  const handleEmailChange = (value) => {
    const res = validateEmail(value.target.value);
    setuserInfo({
      ...userInfo,
      homeA: value.target.value,
    });
    // if (res || validateEmail(userInfo?.homeA)) {
    //   setEmailIsValid(true);
    // } else {
    //   setEmailIsValid(false);
    // }
  };
  console.log("emaiIsValid", emaiIsValid);

  const handleEmail = () => {
    if (validateEmail(userInfo?.homeA)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  };

  //   useEffect(() => {
  //     handleEmail();
  //   }, [userInfo]);

  useEffect(() => {
    if (userInfo?.homeA === "" || userInfo?.homeB === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    if (validateEmail(userInfo?.homeA)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  }, [userInfo]);

  const handleNext = async () => {
    setLoading(true);
    if ((userInfo?.homeA !== "" || userInfo?.homeB) && emaiIsValid) {
      // const db = getDatabase();
      // const checkingIsUserExist = await get(
      //   query(
      //     ref(db, "OnBoardingUsers"),
      //     orderByChild("homeA"),
      //     equalTo(userInfo?.homeA)
      //   )
      // );
      // console.log("checkingIsUserExist", checkingIsUserExist.val());
      // const resp = checkingIsUserExist.val();
      // if (resp) {
      //   const extractedValues = Object.entries(
      //     checkingIsUserExist.val()
      //   ).reduce((acc, [key, value]) => {
      //     return { ...acc, ...value };
      //   }, {});
      //   setExistingUserInfo(extractedValues);
      //   if (extractedValues?.homeA === userInfo?.homeA) {
      //     console.log("openOnce", openOnce);
      //     if (!openOnce) {
      //       setOpen(true);
      //     } else {
      //       setcurrentPage((page) => page + 1);
      //     }
      //     setOpenOnce(true);
      //     setLoading(false);
      //   }
      // } else {
      //   setcurrentPage((page) => page + 1);
      // }
      if (!openOnce) {
        setOpen(true);
      } else {
        setcurrentPage((page) => page + 1);
      }
      setOpenOnce(true);
      setLoading(false);
      console.log("existingUserInfo", existingUserInfo);
      console.log("userInfoInConsole", userInfo);

      setLoading(false);
    } else {
      setLoading(false);
      setIsEmailDirty(true);
      setfieldRequiredError(true);
    }
  };

  return (
    <>
      <MainBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: "2%",
          }}
        >
          <FormHeading text={"Intake Form"} />
        </Box>
        <Divider sx={{ bgcolor: "#fff", mb: "3%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#252525",
            borderRadius: "10px 10px 10px 10px",
            // height: "80vh",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "120px",
                sm: "125px",
                md: "130px",
                lg: "150px",
                xl: "170px",
              },
            }}
          >
            <img
              style={{
                objectFit: "fill",
                objectPosition: "center",
                borderRadius: "10px 10px 0px 0",
              }}
              src={image}
              alt={"imageSwap"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: { xs: "wrap", md: "nowrap" },
              padding: "5%",
              // bgcolor: "red",
              // columnGap: "30px",
              columnGap: "2rem",
            }}
          >
            <Box
              sx={{
                // width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                margin: "1%",
                // bgcolor: "pink",
                flexGrow: 1,
                width: { xs: "200px", md: "300px" },
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "#00BDDF",
                  fontWeight: "600",
                  fontSize: {
                    xs: "17px",
                    sm: "17px",
                    md: "18px",
                    lg: "22px",
                    xl: "25px",
                  },
                  marginLeft: "1.2%",
                }}
              >
                Business Email
              </Typography>
              <Box
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: { xs: "20px", md: "30px" },
                }}
              >
                <Typography
                  fontWeight={200}
                  textAlign={"start"}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    marginBottom: "1%",
                    marginLeft: "1%",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                  }}
                >
                  {/* Enter your business email we will if already have your form
                  filled */}
                  Enter your business email
                </Typography>
                <TextField
                  id="filled-basic"
                  multiline={true}
                  maxRows={3}
                  placeholder="lorem@example.com"
                  variant="filled"
                  type="email"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "17px",
                      },
                    },
                  }}
                  // autoComplete="off"
                  value={userInfo?.homeA}
                  onChange={(value) => handleEmailChange(value)}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setIsEmailDirty(true);
                    },
                  }}
                  helperText={
                    !emaiIsValid && isEmailDirty
                      ? userInfo?.homeA == ""
                        ? "This field is required"
                        : "Please enter valid email"
                      : ""
                  }
                  error={!emaiIsValid && isEmailDirty ? true : false}
                />
              </Box>
            </Box>
            <Box
              sx={{
                // width: "90%",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                margin: "1%",
                flexGrow: 1,
                width: { xs: "200px", md: "300px" },
              }}
            >
              <Typography
                fontWeight={200}
                textAlign={"start"}
                sx={{
                  color: "#00BDDF",
                  fontWeight: "600",
                  fontSize: {
                    xs: "17px",
                    sm: "17px",
                    md: "18px",
                    lg: "22px",
                    xl: "25px",
                  },
                  marginLeft: "1.2%",
                }}
              >
                Project Name
              </Typography>
              <Box
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: { xs: "20px", md: "30px" },
                }}
              >
                <Typography
                  fontWeight={200}
                  textAlign={"start"}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    marginBottom: "1%",
                    marginLeft: "1%",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                  }}
                >
                  Enter your project name
                </Typography>
                <TextField
                  id="filled-basic"
                  multiline={true}
                  maxRows={3}
                  placeholder="Automation Project"
                  variant="filled"
                  // autoComplete="off"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "17px",
                      },
                    },
                  }}
                  value={userInfo?.homeB}
                  onChange={(value) =>
                    setuserInfo({
                      ...userInfo,
                      homeB: value.target.value,
                    })
                  }
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setInputRequiredError((prevState) => ({
                        ...prevState,
                        answer2: true,
                      }));
                    },
                  }}
                  helperText={
                    inputRequiredError.answer2 && userInfo?.homeB == ""
                      ? "This field is required"
                      : ""
                  }
                  error={
                    inputRequiredError.answer2 && userInfo?.homeB == ""
                      ? true
                      : false
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              columnGap: { xs: "10px", md: "30px" },
              p: "3%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                bgcolor: "transparent",
                "&:hover": { bgcolor: "transparent" },
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "16px",
                },
              }}
              onClick={() => {
                setcurrentPage((step) => step - 1);
                // setcurrentQuestion(0);
              }}
            >
              Previous
            </Button>
            {/* <div
              style={{
                width: "0.1%",
                height: "100%",
                backgroundColor: "white",
              }}
            ></div> */}
            <Divider
              orientation="vertical"
              sx={{ bgcolor: "#fff", width: "0.1%", height: "35px" }}
            />
            <Button
              disabled={isDisabled}
              onClick={handleNext}
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                // bgcolor: "#24B2C5",
                // "&:hover": { bgcolor: "#24B2C5" },
                cursor: isDisabled ? "not-allowed" : "pointer",
                bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
                "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
                "&.Mui-disabled": {
                  background: "#5A5A5A",
                  color: "#ffff",
                  cursor: "not-allowed",
                },
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "16px",
                },
              }}
            >
              {loading ? "Loading..." : " Next"}
            </Button>
          </Box>
        </Box>
        <ModalForExisitingUser ExUserInfo={existingUserInfo} />
      </MainBox>
    </>
  );
}

export default BusinessInfo;
