import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";

const ReviewsPlatformCard = ({
  img,
  btnText,
  checkBoxText,
  ischecked,
  handleChange,
  link,
  imgWidth,
}) => {
  return (
    <Box sx={{ width: { xs: "100%" } }}>
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          height: { xs: "160px", md: "140px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: "150px", height: "30px" }}>
            <img
              src={img}
              width={"100%"}
              height={"100%"}
              alt="platformImg"
              style={{ objectFit: "contain" }}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              "& > a": {
                textDecoration: "none",
                color: "#fff",
              },
              fontSize: "12px",
              width: "max-content",
              textTransform: "capitalize",
              background: "#079AB5",
              "&:hover": {
                background: "#079AB5",
              },
            }}
          >
            <a href={link} target="_blank">
              {btnText}
            </a>
          </Button>
        </Box>
      </Box>
      <Stack
        direction={"row"}
        // justifyContent={"flex-start"}
        // alignItems={"center"}
        gap={"1px"}
        mt={"5px"}
        height={"50px"}
      >
        <div>
          <Checkbox
            size="small"
            sx={{
              color: "#079AB5",
              "&.Mui-checked": {
                color: "#079AB5",
              },
            }}
            checked={ischecked}
            onChange={handleChange}
          />
        </div>
        <Typography
          fontSize={"11px"}
          sx={{ mt: "10px" }}
          textAlign={"start"}
          variant="body2"
        >
          {checkBoxText}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ReviewsPlatformCard;
