import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import image from "../../../Assets/images/artboard-7.webp";
import platform1 from "../../../Assets/images/platform1.webp";
import platform2 from "../../../Assets/images/platform2.webp";
import platform3 from "../../../Assets/images/platform3.png";
import {
  TrackCurrentQuestionNum,
  TrackCurrentStepNum,
} from "../FormPage/FormPage";
import { useAtom } from "jotai";
import { useState } from "react";
import { useEffect } from "react";
import ReviewsPlatformCard from "./ReviewsPlatformCard";
import { userDataAtom } from "../Home/Homes";

const ReviewsOnPlatform = () => {
  const [userInfo, setuserInfo] = useAtom(userDataAtom);

  // const [isChecked1, setIsChecked1] = useState(false);
  // const [isChecked2, setIsChecked2] = useState(false);
  // const [isChecked3, setIsChecked3] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [currentQuestion, setcurrentQuestion] = useAtom(
    TrackCurrentQuestionNum
  );
  const [currActiveStepAtom, setcurrActiveStepAtom] =
    useAtom(TrackCurrentStepNum);

  const handleCheckbox1Change = (event) => {
    if (currActiveStepAtom === 6) {
      setuserInfo((prevUserData) => ({
        ...prevUserData,
        reviewA: event.target.checked,
      }));
    }
  };

  const handleCheckbox2Change = (event) => {
    if (currActiveStepAtom === 6) {
      setuserInfo((prevUserData) => ({
        ...prevUserData,
        reviewB: event.target.checked,
      }));
    }
  };
  const handleCheckbox3Change = (event) => {
    if (currActiveStepAtom === 6) {
      setuserInfo((prevUserData) => ({
        ...prevUserData,
        reviewC: event.target.checked,
      }));
    }
  };

  useEffect(() => {
    if (!userInfo?.reviewA && !userInfo?.reviewB && !userInfo?.reviewC) {
      setIsDisabled(true);
      //  setError(true);
    } else {
      setIsDisabled(false);
      setError(false);
    }
  }, [userInfo]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#252525",
        // height: "80vh",
        borderRadius: "10px",
        width: "70%",
        alignSelf: "center",
        "@media(max-width:900px)": { width: "100%" },
      }}
    >
      <Box
        sx={{
          height: {
            xs: "120px",
            sm: "125px",
            md: "130px",
            lg: "150px",
            xl: "170px",
          },
        }}
      >
        <img
          style={{
            objectFit: "fill",
            objectPosition: "center",
            borderRadius: "10px 10px 0px 0",
          }}
          src={image}
          alt={"imageSwap"}
          height={"100%"}
          width={"100%"}
        />
      </Box>
      <Stack
        display={"flex"}
        // justifyContent={"center"}
        // alignItems={"center"}
        width={"100%"}
        // p={"2%"}
      >
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
            margin: "2%",
            // py: "40px",
            color: "#fff",
          }}
        >
          <Typography
            fontWeight={200}
            textAlign={"start"}
            sx={{
              color: "#00BDDF",
              fontWeight: "600",
              fontSize: "21px",
            }}
          >
            Review us on other Platfroms
          </Typography>
          <Stack
            direction={"row"}
            // justifyContent={"center"}
            alignItems={"center"}
            gap={"20px"}
            flexGrow={1}
            sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
          >
            <ReviewsPlatformCard
              img={platform1}
              imgWidth={"120px"}
              ischecked={userInfo?.reviewA}
              btnText={"Schedule a Call"}
              handleChange={handleCheckbox1Change}
              checkBoxText={
                " I have schedule a call with clutch to provide review to tezeract"
              }
              link={"https://review.clutch.co/review/?provider_id=1843409"}
            />
            <ReviewsPlatformCard
              img={platform2}
              imgWidth={"160px"}
              ischecked={userInfo?.reviewB}
              btnText={"  Visit to Review"}
              handleChange={handleCheckbox2Change}
              checkBoxText={"I have reviewed a Tezeract on Goodfirms"}
              link={
                "https://account.goodfirms.co/reviewers/signin?next=https%3A%2F%2Fwww.goodfirms.co%2Freview%2Fcompany%2Fadd%2F84489"
              }
            />

            <ReviewsPlatformCard
              img={platform3}
              imgWidth={"150px"}
              ischecked={userInfo?.reviewC}
              btnText={"Visit to Review"}
              handleChange={handleCheckbox3Change}
              checkBoxText={"I have reviewed a Tezeract on Techbehemoths "}
              link={"https://techbehemoths.com/company/review/submit/tezeract"}
            />
          </Stack>
          {error && (
            <Box sx={{ mt: "2%" }}>
              <Typography
                sx={{ color: "#d32f2f", textAlign: "start", fontSize: "13px" }}
              >
                Please ensure that you have selected any one option.
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              columnGap: "30px",
              mt: "10%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "max-content",
                alignSelf: "flex-end",
                bgcolor: "transparent",
                "&:hover": { bgcolor: "transparent" },
              }}
              onClick={() => {
                {
                  setcurrActiveStepAtom((step) => step - 1);
                }
                setcurrentQuestion(0);
              }}
            >
              Previous
            </Button>

            {/* <Divider
              orientation="vertical"
              sx={{ backgroundColor: "white", height: "32px" }}
              variant="middle"
            /> */}
            <div
              style={{
                width: "0.1%",
                height: "40px",
                backgroundColor: "white",
              }}
            ></div>
            <Button
              // disabled={!isDisabled}

              onClick={() => {
                if (isDisabled) {
                  setError(true);
                } else {
                  setcurrActiveStepAtom((step) => step + 1);
                  setcurrentQuestion(0);
                }
                // handleDone();
              }}
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
                "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default ReviewsOnPlatform;
