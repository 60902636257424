import React, { useState } from "react";
import { Box } from "@mui/material";
import { Collapse } from "react-collapse";

export default function StepperDot({
  stepNum,
  activeQuestionStatus,
  activeStepStatus,
}) {
  console.log("activeStepStatus", activeStepStatus);
  const [first, setfirst] = useState(false);
  return (
    <>
      <Box
        onClick={() => setfirst(!first)}
        sx={{
          bgcolor: activeStepStatus === "activeStep" ? "#24B2C5" : "#636363",
          width: { xs: "30px", sm: "32px", md: "40px", lg: "44px" },
          height: { xs: "30px", sm: "32px", md: "40px", lg: "44px" },
          borderRadius: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: stepNum == 5 ? "0px" : "15px",
          mt: stepNum == 1 ? "0px" : "15px",
        }}
      >
        <div
          style={{
            color: "white",
            fontWeight: "600",
          }}
        >
          {stepNum}
        </div>
      </Box>
      {stepNum != 6 && (
        <Collapse
          initialStyle={{ height: 0, overflow: "hidden" }}
          isOpened={activeStepStatus === "activeStep" ? true : false}
        >
          <Box
            sx={{
              //   bgcolor: "orange",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              height: stepNum === 5 || stepNum === 3 ? "160px" : "120px",
              gap: { md: "10px", sm: "10px", lg: "10px" },
              // rowGap: { md: "40px", sm: "40px", lg: "40px" },
              // py: "10px",
              // mt: stepNum === 5 ? "10px" : "",
            }}
          >
            {stepNum != 6 && (
              <Box
                sx={{
                  bgcolor:
                    activeQuestionStatus === "activeFirstDot"
                      ? "white"
                      : "#797979",
                  width: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  height: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  borderRadius: "100px",
                }}
              ></Box>
            )}
            {stepNum != 6 && (
              <Box
                sx={{
                  bgcolor:
                    activeQuestionStatus === "activeSecondDot"
                      ? "white"
                      : "#797979",
                  width: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  height: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  borderRadius: "100px",
                }}
              ></Box>
            )}
            {(stepNum === 5 || stepNum === 3) && (
              <Box
                sx={{
                  bgcolor:
                    activeQuestionStatus === "activeThirdDot"
                      ? "white"
                      : "#797979",
                  width: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  height: { xs: "6px", sm: "6px", md: "8px", lg: "10px" },
                  borderRadius: "100px",
                }}
              ></Box>
            )}
          </Box>
        </Collapse>
      )}
    </>
  );
}
