import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  styled,
} from "@mui/material";

import { useAtom, atom } from "jotai";
import { userDataAtom } from "../../pages/onBoarding/Home/Homes";
import { useNavigate } from "react-router-dom";
import InstructionBox from "../InstructionBox/InstructionBox";
import FirstTierInput from "../FirstTierInput/FirstTierInput";
import blur1 from "../../Assets/images/Image-2.webp";
import blur2 from "../../Assets/images/Image-3.webp";
import blur3 from "../../Assets/images/Image-4.webp";
import blur4 from "../../Assets/images/Image-5.webp";
import blur5 from "../../Assets/images/Image-6.webp";
import { TrackCurrentQuestionNum } from "../../pages/onBoarding/FormPage/FormPage";
import { TrackCurrentPage } from "../../pages/onBoarding";
import { openModal } from "../ModalForExistUser/ModalForExistUser";

const ScrollBox = styled(Box)({
  paddingRight: "10px",
  width: "100%",
  maxHeight: "350px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  py: "10px",

  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#393939",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#797979",
    borderRadius: "20px",
  },
});

export default function FormWithImage({
  mainHeading,
  Question1,
  Question2,
  Question3,
  //   setcurrentQuestion,
  stepNum,
  setcurrentStep,
  artboardImage,
  setuserInfo,
  answer1,
  answer2,
  answer3,
}) {
  const navigate = useNavigate();
  console.log(
    " FormWithImage props",
    mainHeading,
    Question1,
    Question2,
    Question3,
    stepNum
  );
  const [userAtom, setuserAtom] = useAtom(userDataAtom);
  console.log("userAtom props", userAtom);
  const [currentQuestion, setcurrentQuestion] = useAtom(
    TrackCurrentQuestionNum
  );
  const [currentHomePage, setcurrentHomePage] = useAtom(TrackCurrentPage);
  const [inputRequiredError, setInputRequiredError] = useState({
    answer1: false,
    answer2: false,
    answer3: false,
  });

  const [placeholderA, setPlaceholderA] = useState("");
  const [placeholderB, setPlaceholderB] = useState("");
  const [placeholderC, setPlaceholderC] = useState("");
  const [instructionImg, setInstructionImg] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [scrollId, setScrollId] = useState("#empty");
  const [open, setOpen] = useAtom(openModal);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const containerRef = useRef(null);
  const handleFirstInputChange = (event) => {
    const { name, value } = event.target;
    console.log(" handleInputChange", name, value);

    if (stepNum === 2) {
      setuserInfo({
        ...userAtom,
        twoA: value,
        // twoB: value,
      });
    }
    if (stepNum === 3) {
      setuserInfo({ ...userAtom, threeA: value });
    }
    if (stepNum === 4) {
      setuserInfo({
        ...userAtom,
        fourA: value,
        // fourB: value,
      });
    }
    if (stepNum === 5) {
      setuserInfo({ ...userAtom, fiveA: value });
    }
  };

  const handleSecondInputChange = (event) => {
    const { name, value } = event.target;
    console.log(" handleInputChange", name, value);

    // setInputValue((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if (stepNum === 1) {
      setuserInfo({
        ...userAtom,
        // oneA: value,
        oneB: value,
      });
    }
    if (stepNum === 2) {
      setuserInfo({
        ...userAtom,
        // twoA: value,
        twoB: value,
      });
    }
    if (stepNum === 3) {
      setuserInfo({ ...userAtom, threeB: value });
    }
    if (stepNum === 4) {
      setuserInfo({
        ...userAtom,
        fourB: value,
      });
    }
    if (stepNum === 5) {
      setuserInfo({ ...userAtom, fiveB: value });
    }
  };

  const handleThirdInputChange = (event) => {
    const { name, value } = event.target;
    console.log(" handleInputChange", name, value);

    if (stepNum === 3) {
      setuserInfo({
        ...userAtom,
        threeC: value,
      });
    }
    if (stepNum === 5) {
      setuserInfo({
        ...userAtom,
        fiveC: value,
      });
    }
  };

  //Updating disable button
  useEffect(() => {
    if (stepNum === 1) {
      if (
        !answer1[0] ||
        !answer1[1] ||
        !answer1[2] ||
        !answer1[3] ||
        !answer1[4] ||
        !answer2
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      if (answer1 === "" || answer2 === "" || answer3 === "") {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    if (answer1 === "") {
      setScrollId("#box-1");
    } else if (answer2 === "") {
      setScrollId("#box-2");
    } else {
      setScrollId("#box-3");
    }
  }, [userAtom, stepNum]);

  const handleFocusOnEmptyInput = () => {
    let emptyInputName;
    if (answer1 === "") {
      emptyInputName = "answer1";
    } else if (answer2 === "") {
      emptyInputName = "answer2";
    } else if (answer3 === "") {
      emptyInputName = "answer3";
    }

    if (emptyInputName) {
      const emptyInput = document.querySelector(
        `input[name="${emptyInputName}"]`
      );
      if (emptyInput) {
        const offsetTop = emptyInput.getBoundingClientRect().top;
        const currentScrollY = window.scrollY;
        const targetScrollY = currentScrollY + offsetTop - 100; // To adjust the scroll position

        window.scrollTo({
          top: targetScrollY,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (stepNum === 1) {
      setPlaceholderA(" Local coffee shops, Parks, Social media groups");
      setPlaceholderB("It could be on services, communication, deliverables");
      setInstructionImg(blur1);
    } else if (stepNum === 2) {
      setPlaceholderA("Feel free to share your actual experience");
      setPlaceholderB("In Terms of project management or communication");
      setInstructionImg(blur2);
    } else if (stepNum === 3) {
      setPlaceholderA("You may write about our expertise and support");
      setPlaceholderB("Don't hesitate to provide your opinions");
      setPlaceholderC("Provide real-time Chat Support");
      setInstructionImg(blur3);
    } else if (stepNum === 4) {
      setPlaceholderA(
        "It could be on project delivery, cost, or problem solving"
      );
      setPlaceholderB("Please feel comfortable sharing your feedback with us.");
      setInstructionImg(blur4);
    } else {
      setPlaceholderA("It should be based on your experience");
      setPlaceholderB(
        "Any tech companies seeking AI solutions to automate their business."
      );
      setPlaceholderC("Open to reconsidering in the future");
      setInstructionImg(blur5);
    }
  }, [stepNum]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#252525",
        // height: "80vh",
        borderRadius: "10px",
        width: "80%",
        "@media(max-width:900px)": { width: "100%" },
      }}
    >
      <Box
        sx={{
          height: {
            xs: "120px",
            sm: "125px",
            md: "130px",
            lg: "150px",
            xl: "170px",
          },
        }}
      >
        {/* {currentQuestion === 0 ||
        stepNum === 3 ||
        stepNum === 4 ||
        stepNum === 5 ||
        currentQuestion === 5.1 ? (
          <Box sx={{ height: "100%" }}>
            <img
              style={{
                objectFit: "cover",
                backgroundSize: "cover",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              src={artboardImage}
              alt={"imageSwap"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
        ) : ( */}
        <Box sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
          <InstructionBox
            // img={currentQuestion === 0 ? artboardImage : artboardImage}
            img={artboardImage}
            stepNum={stepNum}
          />
        </Box>
        {/* )} */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "2%",
          marginTop: "15px",
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            margin: "2%",
          }}
        >
          <Typography
            fontWeight={200}
            textAlign={"start"}
            textTransform={"capitalize"}
            sx={{
              color: "#079AB5",
              fontWeight: "600",
              fontSize: "21px",
              textTransform: "capitalize",
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "18px",
                lg: "20px",
                xl: "22px",
              },
              marginLeft: "1%",
            }}
          >
            {mainHeading}
          </Typography>

          <ScrollBox ref={containerRef}>
            <Box
              id="box-1"
              sx={{
                bgcolor: "#3C3C3C",
                borderRadius: "10px",
                padding: stepNum === 1 ? "2%" : "4%",
              }}
            >
              <Typography
                fontWeight={200}
                sx={{
                  color: "white",
                  fontWeight: "400",
                  textAlign: "start",
                  marginBottom: "1%",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                {Question1}
              </Typography>
              {stepNum === 1 ? (
                <FirstTierInput
                  answer1={answer1}
                  setcurrentQuestion={setcurrentQuestion}
                  inputRequiredError={inputRequiredError}
                  isDisabled={isDisabled}
                />
              ) : (
                <TextField
                  multiline={true}
                  maxRows={3}
                  name="answer1"
                  value={answer1}
                  onChange={handleFirstInputChange}
                  placeholder={placeholderA}
                  variant="filled"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "16px",
                      },
                    },
                  }}
                  onFocus={() => {
                    if (stepNum === 1) {
                      setcurrentQuestion(1.1);
                    } else if (stepNum === 2) {
                      setcurrentQuestion(2.1);
                    } else if (stepNum === 3) {
                      setcurrentQuestion(3.1);
                    } else if (stepNum === 4) {
                      setcurrentQuestion(4.1);
                    } else if (stepNum === 5) {
                      setcurrentQuestion(5.1);
                    }
                  }}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setInputRequiredError((prevState) => ({
                        ...prevState,
                        answer1: true,
                      }));
                    },
                  }}
                  helperText={
                    inputRequiredError.answer1 && answer1 == ""
                      ? "This field is required"
                      : ""
                  }
                  error={
                    inputRequiredError.answer1 && answer1 == "" ? true : false
                  }
                  ref={input1Ref}
                />
              )}
            </Box>
            {stepNum != 6 && (
              <Box
                id="box-2"
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: "4%",
                }}
              >
                <Typography
                  fontWeight={200}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    textAlign: "start",
                    marginBottom: "1%",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {Question2}
                </Typography>
                <TextField
                  multiline={true}
                  maxRows={3}
                  value={answer2}
                  name="answer2"
                  id="filled-basic-2"
                  //   label="Filled"
                  placeholder={placeholderB}
                  onChange={handleSecondInputChange}
                  variant="filled"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "16px",
                      },
                    },
                  }}
                  onFocus={() => {
                    if (stepNum === 1) {
                      setcurrentQuestion(1.2);
                    } else if (stepNum === 2) {
                      setcurrentQuestion(2.2);
                    } else if (stepNum === 3) {
                      setcurrentQuestion(3.2);
                    } else if (stepNum === 4) {
                      setcurrentQuestion(4.2);
                    } else if (stepNum === 5) {
                      setcurrentQuestion(5.2);
                    }
                  }}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setInputRequiredError((prevState) => ({
                        ...prevState,
                        answer2: true,
                      }));
                    },
                  }}
                  helperText={
                    inputRequiredError.answer2 && answer2 == ""
                      ? "This field is required"
                      : ""
                  }
                  error={
                    inputRequiredError.answer2 && answer2 == "" ? true : false
                  }
                  ref={input2Ref}
                />
              </Box>
            )}
            {(stepNum === 3 || stepNum === 5) && (
              <Box
                id="box-3"
                sx={{
                  bgcolor: "#3C3C3C",
                  borderRadius: "10px",
                  padding: "4%",
                }}
              >
                <Typography
                  fontWeight={200}
                  sx={{
                    color: "white",
                    fontWeight: "400",
                    textAlign: "start",
                    marginBottom: "1%",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {Question3}
                </Typography>
                <TextField
                  multiline={true}
                  maxRows={3}
                  value={answer3}
                  name="answer3"
                  id="filled-basic-2"
                  //   label="Filled"
                  placeholder={placeholderC}
                  onChange={handleThirdInputChange}
                  variant="filled"
                  sx={{
                    width: "100%",
                    textarea: {
                      "::placeholder": { color: "#FFFFFF" },
                      color: "white",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "16px",
                      },
                    },
                  }}
                  onFocus={() => {
                    if (stepNum === 1) {
                      setcurrentQuestion(1.3);
                    } else if (stepNum === 2) {
                      setcurrentQuestion(2.3);
                    } else if (stepNum === 3) {
                      setcurrentQuestion(3.3);
                    } else if (stepNum === 4) {
                      setcurrentQuestion(4.3);
                    } else if (stepNum === 5) {
                      setcurrentQuestion(5.3);
                    }
                  }}
                  FormHelperTextProps={{
                    style: {
                      backgroundColor: "transparent",
                      marginLeft: "0rem",
                      marginRight: "0rem",
                      marginTop: "0rem",
                    },
                  }}
                  inputProps={{
                    onBlur: () => {
                      setInputRequiredError((prevState) => ({
                        ...prevState,
                        answer3: true,
                      }));
                    },
                  }}
                  helperText={
                    inputRequiredError.answer3 && answer3 == ""
                      ? "This field is required"
                      : ""
                  }
                  error={
                    inputRequiredError.answer3 && answer3 == "" ? true : false
                  }
                  ref={input3Ref}
                />
              </Box>
            )}
          </ScrollBox>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              columnGap: "30px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                bgcolor: "transparent",
                "&:hover": { bgcolor: "transparent" },
              }}
              onClick={() => {
                {
                  if (stepNum == 1) {
                    setcurrentHomePage((step) => step - 1);
                  } else setcurrentStep((step) => step - 1);
                }
                setcurrentQuestion(0);
                setOpen(false);
              }}
            >
              Previous
            </Button>
            <div
              style={{
                width: "0.1%",
                height: "100%",
                backgroundColor: "white",
              }}
            ></div>
            <Button
              onClick={() => {
                // handleFocusOnEmptyInput();
                if (answer1 === "") {
                  input1Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                } else if (answer2 === "") {
                  input2Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                } else {
                  input3Ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                }
                if (stepNum === 2 || stepNum === 4) {
                  console.log("not step 5", stepNum);
                  if (!answer1 || !answer2) {
                    console.log("true", answer1, answer2);
                    setInputRequiredError({ answer1: true, answer2: true });
                  } else if (answer1 && answer2) {
                    setcurrentStep((step) => step + 1);
                    setInputRequiredError({ answer1: false, answer2: false });
                  }
                } else if (stepNum === 3 || stepNum === 5) {
                  if (!answer1 || !answer2 || !answer3) {
                    setInputRequiredError({
                      answer1: true,
                      answer2: true,
                      answer3: true,
                    });
                  } else if (answer1 && answer2 && answer3) {
                    setcurrentStep((step) => step + 1);
                    setInputRequiredError({
                      answer1: false,
                      answer2: false,
                      answer3: false,
                    });
                  }
                } else if (stepNum === 1) {
                  if (
                    !answer1[0] ||
                    !answer1[1] ||
                    !answer1[2] ||
                    !answer1[3] ||
                    !answer1[4] ||
                    !answer2
                  ) {
                    console.log("true", answer1, answer2);
                    setInputRequiredError({ answer1: true, answer2: true });
                  } else if (
                    answer1[0] &&
                    answer1[1] &&
                    answer1[2] &&
                    answer1[3] &&
                    answer1[4] &&
                    answer2
                  ) {
                    setcurrentStep((step) => step + 1);
                    setInputRequiredError({ answer1: false, answer2: false });
                  }
                }

                setcurrentQuestion(0);
                // handleDone();
              }}
              variant="contained"
              sx={{
                width: "fit-content",
                alignSelf: "flex-end",
                // cursor: isDisabled ? "not-allowed" : "pointer",
                bgcolor: isDisabled ? "#5A5A5A" : "#079AB5",
                "&:hover": { bgcolor: isDisabled ? "#5A5A5A" : "#079AB5" },
                "&.Mui-disabled": {
                  background: "#5A5A5A",
                  color: "#ffff",
                  cursor: "not-allowed",
                },
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
