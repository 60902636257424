import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
} from "@mui/material";
// import image1 from "../../../Assets/images/artboard-6.webp";
import image2 from "../../../Assets/images/artboard-1.webp";
import image3 from "../../../Assets/images/artboard-2.webp";
import image4 from "../../../Assets/images/artboard-3.webp";
import image5 from "../../../Assets/images/artboard-4.webp";
import image6 from "../../../Assets/images/artboard-5.webp";

import FormWithImage from "../../../components/FormWithImage/FormWithImage";
import Stepper from "../../../components/Stepper/Stepper";
import { useAtom, atom } from "jotai";
import RecordOrUploadVideoForm from "../RecordOrUploadVideoForm/RecordOrUploadVideoForm";
import CameraScreen from "../CameraScreen/CameraScreen";
import ReviewsOnPlatform from "../ReviewsOnPlatform/ReviewsOnPlatform";
import { userDataAtom } from "../Home/Homes";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import MainBox from "../../../components/common/MainBox";
export const TrackCurrentQuestionNum = atom(0);
export const TrackCurrentStepNum = atom(1);

function FormPage(props) {
  const [currentStep, setcurrentStep] = useAtom(TrackCurrentStepNum);
  const [userInfo, setuserInfo] = useAtom(userDataAtom);

  useEffect(() => {
    console.log("userInfo: ", userInfo);
  }, [userInfo]);

  const renderCurrentForm = () => {
    console.log("renderCurrentForm", currentStep);
    switch (currentStep) {
      case 1:
        return (
          <FormWithImage
            mainHeading={"Service Rating & Experience"}
            Question1={"How would you rate the quality of our services?"}
            Question2={"How was your overall experience working with us?"}
            stepNum={1}
            setcurrentStep={setcurrentStep}
            artboardImage={image2}
            setuserInfo={setuserInfo}
            answer1={userInfo?.oneA}
            answer2={userInfo?.oneB}
          />
        );
      case 2:
        return (
          <FormWithImage
            mainHeading={"Communication & Collaboration"}
            Question1={
              "How effectively did we listen to your needs and concerns throughout the project?"
            }
            Question2={
              "How would you describe our professionalism and reliability during the project? "
            }
            stepNum={2}
            setcurrentStep={setcurrentStep}
            artboardImage={image3}
            setuserInfo={setuserInfo}
            answer1={userInfo?.twoA}
            answer2={userInfo?.twoB}
          />
        );
      case 3:
        return (
          <FormWithImage
            mainHeading={"Service Highlights"}
            Question1={"What did you like about our service?"}
            Question2={"What didn’t you like about our service?"}
            Question3={
              "What suggestions do you have to improve our overall experience of service?"
            }
            stepNum={3}
            setcurrentStep={setcurrentStep}
            artboardImage={image4}
            setuserInfo={setuserInfo}
            answer1={userInfo?.threeA}
            answer2={userInfo?.threeB}
            answer3={userInfo?.threeC}
          />
        );
      case 4:
        return (
          <FormWithImage
            mainHeading={"Project Assessment"}
            Question1={
              "Specifically highlight the most important achievement during the project?"
            }
            Question2={
              "Did we meet your expectations? Please highlight if you face any difficulty during the project."
            }
            stepNum={4}
            setcurrentStep={setcurrentStep}
            artboardImage={image5}
            setuserInfo={setuserInfo}
            answer1={userInfo?.fourA}
            answer2={userInfo?.fourB}
          />
        );
      case 5:
        return (
          <FormWithImage
            mainHeading={"Satisfaction & Recommendations"}
            Question1={
              "How likely are you to recommend our services to a friend or colleague?"
            }
            Question2={
              "Who do you think is the ideal customer for our service? (In your network) "
            }
            Question3={"Would you reconsider our services in the future?"}
            stepNum={5}
            setcurrentStep={setcurrentStep}
            artboardImage={image6}
            setuserInfo={setuserInfo}
            answer1={userInfo?.fiveA}
            answer2={userInfo?.fiveB}
            answer3={userInfo?.fiveC}
          />
        );
      case 6:
        return <ReviewsOnPlatform />;
      case 7:
        return <RecordOrUploadVideoForm />;
      case 8:
        return <CameraScreen />;
      default:
        return null;
    }
  };

  return (
    <>
      <MainBox
      // sx={{
      //   height: "100%",
      //   pb: "20px",
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space=between",
      //   rowGap: "12px",
      //   backgroundColor: "#111111",
      //   //   "@media(max-width:800px)": {
      //   //     height: "fit-content",
      //   //     padding: "0px 0 40px 0",
      //   //   },
      // }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "4px",
            my: "2%",
            flexWrap: "wrap",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <FormHeading text={"Experience Survey"} />
          <Box
            sx={{
              color: "#00F0FF",
              fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
            }}
          >
            <Typography className="progress">
              Off-boarding In Progress
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ bgcolor: "#fff", mb: "4%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // bgcolor: "#252525",
            // height: "80vh",
            width: "90%",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "30px",
          }}
        >
          <Stepper />
          {renderCurrentForm()}
        </Box>
      </MainBox>
    </>
  );
}

export default FormPage;
