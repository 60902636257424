import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  useTheme,
  Stack,
} from "@mui/material";
import { storage } from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ProgressBar from "@ramonak/react-progress-bar";
// import { userDataAtom } from "../Home/Home";

import { useAtom, atom } from "jotai";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import DoneIcon from "@mui/icons-material/Done";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgressBar from "../../../components/CircularProgressBar/CircularProgressBar";
import Swal from "sweetalert2";
import successAlert from "../../../Assets/images/success.webp";
import failAlert from "../../../Assets/images/failed.webp";
import { useNavigate } from "react-router-dom";
import { userDataAtom } from "../Home/Homes";
import { TrackCurrentStepNum } from "../FormPage/FormPage";
import { ArrowBack } from "@mui/icons-material";
import { TrackCurrentPage } from "..";
import dayjs from "dayjs";

const CameraWithWebcam = () => {
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useAtom(TrackCurrentPage);
  const theme = useTheme();
  const [userInfo, setuserInfo] = useAtom(userDataAtom);
  const [currActiveStepAtom, setcurrActiveStepAtom] =
    useAtom(TrackCurrentStepNum);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recording, setRecording] = useState(false);
  const [progress, setProgress] = useState(0);

  const [videoUploadLoading, setvideoUploadLoading] = useState(false);
  const [videoUploadPercentage, setvideoUploadPercentage] = useState();
  let videoDuration = useRef(null);

  useEffect(() => {
    const startCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        webcamRef.current.srcObject = mediaStream;
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    startCamera();

    return () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  const handleStartRecording = () => {
    const options = { mimeType: "video/webm; codecs=vp9" };
    const mediaRecorder = new MediaRecorder(webcamRef.current.stream, options);

    mediaRecorderRef.current = mediaRecorder;
    setRecordedChunks([]);
    // setProgress(0);
    setRecording(true);
    setProgress(0);
    startTimer();

    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
  };
  const formattedDate = dayjs(userInfo?.homeC)?.format("DD/MM/YYYY");
  const {
    homeA,
    homeB,
    homeC,
    homeD,
    oneA,
    oneB,
    twoA,
    twoB,
    threeA,
    threeB,
    threeC,
    fourA,
    fourB,
    fiveA,
    fiveB,
    fiveC,
    reviewA,
    reviewB,
    reviewC,
  } = userInfo;

  const userDetails = {
    homeA: { Q: "Email", A: homeA },
    homeB: { Q: "Project Name", A: homeB },
    homeC: { Q: "Date Of Birth", A: formattedDate },
    homeD: { Q: "Name", A: homeD },
    oneA: [
      { Q: "Quality Satisfaction", A: oneA[0][1] },
      { Q: "Communication and responsiveness", A: oneA[1][2] },
      { Q: "Timelines and Deadlines", A: oneA[2][3] },
      { Q: "Problem-solving and Adaptability", A: oneA[3][4] },
      { Q: "Overall Satisfaction", A: oneA[4][5] },
    ],
    oneB: { Q: "How was your overall experience working with us?", A: oneB },
    twoA: {
      Q: "How effectively did we listen to your needs and concerns throughout the project?",
      A: twoA,
    },
    twoB: {
      Q: "How would you describe our professionalism and reliability during the project?",
      A: twoB,
    },
    threeA: { Q: "What did you like about our service?", A: threeA },
    threeB: { Q: "What did you dislike about our service?", A: threeB },
    threeC: {
      Q: "What suggestions do you have to improve our overall experience of service?",
      A: threeC,
    },
    fourA: {
      Q: "Specifically highlight the most important achievement during the project?",
      A: fourA,
    },
    fourB: {
      Q: "Did we meet your expectations? Please highlight if you face any difficulty during the project.",
      A: fourB,
    },
    fiveA: {
      Q: "How likely are you to recommend our services to a friend or colleague?",
      A: fiveA,
    },
    fiveB: {
      Q: "Who do you think is the ideal customer for our service? (In your network)",
      A: fiveB,
    },
    fiveC: { Q: "Would you reconsider our services in the future?", A: fiveC },
    reviewA: { Q: "Clutch review", A: reviewA },
    reviewB: { Q: "GoodFirms review", A: reviewB },
    reviewC: { Q: "Tech Behemoths", A: reviewC },
  };
  console.log(userDetails);

  const uploadUserDataToFirebase = async (url) => {
    console.log("url: ", url);
    // await setuserInfo({
    //   ...userInfo,
    //   videoUrl: url,
    // });
    console.log("userinfo before upoload", userInfo);
    const bodyToSend = {
      // ...userInfo,
      ...userDetails,
      videoUrl: url,
    };
    const res = await fetch(
      "https://onboardingapp-12504-default-rtdb.firebaseio.com/OffBoardingUsers.json",
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(bodyToSend),
      }
    );
    console.log("video upoloaded on recording on firebase", res);
  };

  const startTimer = () => {
    clearInterval(videoDuration.current);
    const interval = setInterval(() => {
      setProgress((prevTimer) => prevTimer + 1);
    }, 1000);
    videoDuration.current = interval;
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      clearInterval(videoDuration.current);
      //   setProgress(0);
      setRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
    }
  };

  const handleRetake = () => {
    setRecordedChunks([]);
    setProgress(0);
  };

  //   const handleDone = () => {
  //     const blob = new Blob(recordedChunks, { type: "video/webm" });
  //     const videoUrl = URL.createObjectURL(blob);
  //     console.log(videoUrl);
  //   };

  const handleDone = async () => {
    setvideoUploadLoading(true);
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      const videoRef = ref(storage, `OffBoardingClientVideos/${Date.now()}`);
      console.log("videoRef: ", videoRef);
      const uploadTask = uploadBytesResumable(videoRef, blob);
      console.log("uploadTask", uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload progress: ", Math.round(progress) + "%");
          setvideoUploadPercentage(Math.round(progress));
        },
        (err) => {
          console.log("firebase err", err);
        },
        async () => {
          console.log("Upload complete");

          try {
            const downloadURL = await getDownloadURL(
              uploadTask.snapshot.ref
            ).then((url) => {
              setvideoUploadLoading(false);
              console.log("Video download URL: ", url);
              Swal.fire({
                imageUrl: successAlert,
                imageHeight: 200,
                title: "Uploaded successfully",
                text: "",
                color: "white",
                background: "#252525",
                confirmButtonColor: "#079AB5",
                // icon: "success",
                confirmButtonText: "Continue",
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  // Swal.fire("Saved!", "", "success");
                  // navigate("/final");
                  setcurrentPage((step) => step + 1);
                }
              });
              uploadUserDataToFirebase(url);
            });
            // Use the downloadURL as needed (e.g., store in state or perform further actions)
          } catch (error) {
            console.log("Error getting download URL: ", error);
            Swal.fire({
              allowOutsideClick: false,
              imageUrl: failAlert,
              imageHeight: 200,
              title: "Uploading failed",
              text: "",
              background: "#252525",
              showCancelButton: true,
              color: "white",
              // icon: "success",
              confirmButtonText: "Re-upload",
              confirmButtonColor: "transparent",
              cancelButtonText: "Record",
              cancelButtonColor: "#FF4747",
              allowEscapeKey: false,
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    // const timerId = setInterval(() => {
    //   setProgress((prevProgress) => prevProgress + 1);
    // }, 1000);

    return () => {
      clearInterval(videoDuration);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#252525",
        height: { sm: "100%", xs: "100%" },
        width: "70%",
        alignSelf: "center",
        rowGap: "10px",
        // bgcolor: "pink",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: {
            lg: "flex-end ",
            md: "flex-end",
            sm: "space-around",
            xs: "space-around",
          },
          columnGap: "30px",
        }}
      >
        {recording ? (
          <Button
            onClick={handleStopRecording}
            startIcon={<StopCircleIcon />}
            variant="contained"
            sx={{
              // width: "fit-content",
              alignSelf: "flex-end",
              bgcolor: "#079AB5",
              "&:hover": { bgcolor: "#079AB5" },
            }}
          >
            <span className="hide-label">Stop</span>
          </Button>
        ) : (
          recordedChunks.length === 0 && (
            <Stack direction={"row"} gap={"10px"}>
              <Button
                onClick={() => setcurrActiveStepAtom((curr) => curr - 1)}
                startIcon={<ArrowBack />}
                variant="contained"
                sx={{
                  alignSelf: "flex-end",
                  bgcolor: "#24B2C5",
                  "&:hover": { bgcolor: "#24B2C5" },
                  [theme.breakpoints.down("sm")]: {
                    // padding: "6px", // Reduce padding for smaller size
                    minWidth: "unset", // Remove minimum width
                    width: "fit-content",
                    "& .hide-label": {
                      display: "none",
                    },
                  },
                }}
              >
                <span className="hide-label">Back</span>
              </Button>
              <Button
                onClick={handleStartRecording}
                startIcon={<PlayCircleOutlineIcon />}
                variant="contained"
                sx={{
                  alignSelf: "flex-end",
                  bgcolor: "#24B2C5",
                  "&:hover": { bgcolor: "#24B2C5" },
                  [theme.breakpoints.down("sm")]: {
                    // padding: "6px", // Reduce padding for smaller size
                    minWidth: "unset", // Remove minimum width
                    width: "fit-content",
                    "& .hide-label": {
                      display: "none",
                    },
                  },
                }}
              >
                <span className="hide-label">Start</span>
              </Button>
            </Stack>
            // <Button
            //   onClick={handleStartRecording}
            //   startIcon={<PlayCircleOutlineIcon />}
            //   variant="contained"
            //   sx={{
            //     alignSelf: "flex-end",
            //     bgcolor: "#24B2C5",
            //     "&:hover": { bgcolor: "#24B2C5" },
            //     [theme.breakpoints.down("sm")]: {
            //       // padding: "6px", // Reduce padding for smaller size
            //       minWidth: "unset", // Remove minimum width
            //       width: "fit-content",
            //       "& .hide-label": {
            //         display: "none",
            //       },
            //     },
            //   }}
            // >
            //   <span className="hide-label">Start</span>
            // </Button>
          )
        )}
        {recordedChunks.length > 0 && (
          <React.Fragment>
            <Button
              variant="contained"
              startIcon={<RestartAltIcon />}
              sx={{
                alignSelf: "flex-end",
                bgcolor: "transparent",
                "&:hover": { bgcolor: "#24B2C5" },
                [theme.breakpoints.down("sm")]: {
                  // padding: "6px", // Reduce padding for smaller size
                  minWidth: "unset", // Remove minimum width
                  width: "fit-content",
                  "& .hide-label": {
                    display: "none",
                  },
                },
              }}
              onClick={handleRetake}
            >
              <span className="hide-label">Retake</span>
            </Button>
            <Button
              variant="contained"
              startIcon={<DoneIcon />}
              sx={{
                alignSelf: "flex-end",
                bgcolor: "#24B2C5",
                "&:hover": { bgcolor: "#24B2C5" },
                [theme.breakpoints.down("sm")]: {
                  // padding: "6px", // Reduce padding for smaller size
                  minWidth: "unset", // Remove minimum width
                  width: "fit-content",
                  "& .hide-label": {
                    display: "none",
                  },
                },
              }}
              onClick={handleDone}
            >
              <span className="hide-label">Done</span>
            </Button>
          </React.Fragment>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
          height: { sm: "100%", xs: "100%" },
          // bgcolor: "yellow",
          // height: "100%",
        }}
      >
        <Box
          sx={{
            height: { sm: "100%", xs: "100%" },
            // bgcolor: "orange",
            // height: "100%",
          }}
        >
          <Webcam
            // style={{ transform: "scaleX(-1)" }}
            style={{ objectFit: "fill" }}
            width={"100%"}
            height={"100%"}
            // style={{ width: "100%" }}
            audio={true}
            mirrored={true}
            ref={webcamRef}
            muted={true}
            disabled={true}
            videoConstraints={{
              facingMode: "user",
              // autoGainControl: true,
              // echoCancellation: true,
              // deviceId: { exact: "communication" },
            }}
          />
        </Box>
        <ProgressBar
          completed={progress}
          maxCompleted={60}
          customLabel={`${progress < 60 ? progress : 60} / 60`}
          bgColor="#00C6EB"
          baseBgColor="#005464"
          customLabelStyles={{ whiteSpace: "nowrap" }}
        />
      </Box>
      {videoUploadLoading && (
        <CircularProgressBar percentage={videoUploadPercentage} />
      )}
    </Box>
  );
};

export default CameraWithWebcam;
