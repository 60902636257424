import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import image from "../../../Assets/images/artboard-final.webp";
import success from "../../../Assets/images/success.webp";
import { useAtom, atom } from "jotai";
import { useNavigate } from "react-router-dom";
import FormHeading from "../../../components/common/FormHeading/FormHeading";
import { userDataAtom } from "../Home/Homes";
import axios from "axios";

export default function FinalScreen() {
  const [userInfo, setUserInfo] = useAtom(userDataAtom);
  // const backendUrl = "http://localhost:3002/sendOffboarding";
  const backendUrl = "https://customer-onboarding.tezeract.ai/backend/sendOffboarding";
  const sendEmailToUser = async () => {
    console.log("Function");
    const bodyToSend = {
      name: userInfo?.homeD,
      email: userInfo?.homeA,
    };
    console.log("bodyToSend", JSON.stringify(bodyToSend));
    try {
      const res = await axios.post(`${backendUrl}`, bodyToSend, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log("response", res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Useffect");
    sendEmailToUser();
  }, []);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          pb: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space=between",
          backgroundColor: "#111111",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "4px",
            my: "2%",
            flexWrap: "wrap",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <FormHeading text={"Experience Survey"} />
        </Box>
        <Divider sx={{ bgcolor: "#fff", mb: "4%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#252525",
            // height: "80vh",
            width: "90%",
            alignSelf: "center",
            borderRadius: "10px",
            // "@media(max-width:1000px)": { width: "40%" },
            // "@media(max-width:800px)": { width: "60%" },
            // "@media(max-width:600px)": { width: "90%" },
            // "@media(max-width:450px)": { width: "100%" },
          }}
        >
          <Box
            sx={{
              height: {
                xs: "120px",
                sm: "125px",
                md: "130px",
                lg: "150px",
                xl: "170px",
              },
            }}
          >
            <img
              style={{
                objectFit: "fill",
                objectPosition: "center",
                borderRadius: "10px 10px 0px 0",
              }}
              src={image}
              alt={"success image"}
              height={"100%"}
              width={"100%"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              flexWrap: "wrap",
              padding: "2%",
              // bgcolor: "red",
              alignItems: "center",
              rowGap: "20px",
            }}
          >
            <img
              style={{ objectFit: "contain" }}
              src={success}
              alt={"imageSwap"}
            />
            <Typography
              fontWeight={600}
              textAlign={"center"}
              sx={{
                color: "#00BDDF",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "25px" },
              }}
            >
              Thank you for your valuable feedback!
            </Typography>
            <Typography
              fontWeight={200}
              textAlign={"center"}
              sx={{
                color: "#939393",
                fontSize: { xs: "12px", md: "15px" },
                maxWidth: { xs: "100%", md: "60%" },
              }}
            >
              We sincerely appreciate the chance to serve you as our
              client/partner. Thank you for selecting us as your service
              provider. Best wishes for your future endeavors.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
